.nav {
  .dropdown {
    &.show {
      .nav-link::before {
        display: block;
        border-color: var(--color-secondary-lighter);
      }
    }
  }

  .dropdown-menu {
    padding: 0;
    transform: translate(0, calc(var(--line-height) * 1rem + 2rem)) !important;
    border: var(--border);
  }

  .dropdown-divider {
    margin: 0;
  }

  .dropdown-item {
    color: var(--color-body);
    padding: 0.375rem 1rem;
    &.disabled {
      color: var(--color-body);
    }
    & + .dropdown-item {
      border-top: var(--border);
    }
    @include hover-focus() {
      background-color: $gray-100;
    }
    &:active {
      background-color: $gray-200;
    }
  }

  .dropdown-item:first-child,
  .dropdown-item:last-child {
    border-bottom-left-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
  }
}
