.nav-secondary-mobile,
.burgerMenu-offcanvas {
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.nav-secondary-desktop {
  @include media-breakpoint-down(xs) {
    display: none;
  }
}

body.overlay::after {
  content: '';
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.5;
  z-index: 100;
}

.burgerMenu-icon {
  font-size: 1.6rem;
  margin-left: 0.5rem;
  color: var(--color-dark-lighter);
}

.burgerMenu-navLink {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-right: 0 !important;
}

.burgerMenu-offcanvas {
  position: absolute;
  width: 75%;
  background-color: #fff;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: 0.3s transform;
  border-left: var(--border);
  border-bottom: var(--border);
  border-bottom-left-radius: var(--radius);
  z-index: 1000;
  li {
    padding: 0.5rem 0;
  }
  a {
    color: var(--color-body);
  }
}

.burgerMenu-offcanvas.active {
  transform: translateX(0);
}

.burgerMenu-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem 15px 0 1.5rem;

  font-size: 1.6rem;
  color: var(--color-dark-lighter);
}

.burgerMenu-menu {
  list-style: none;
  text-align: right;
  margin-top: 70px;
  margin-bottom: 20px;
  padding: 15px;
}

.burgerMenu-logout {
  border-top: var(--border);
}

.burgerMenu-logged {
  border-bottom: var(--border);
}
