/* Bootstrap Sass overrides */

// main colors
$primary: #33805d;
$secondary: #bd513a;
$dark: #555;

// font colors
$body-color: #040404;
$text-muted: #6c757d;
$input-placeholder-color: $text-muted;

// border colors
$border-color: #999;
$border-color-lighter: rgba(0, 0, 0, 0.2);
$input-border-color: $border-color;
$input-focus-border-color: $border-color;
$list-group-border-color: $border-color;
$hr-border-color: $border-color-lighter;

// box shadows
$input-focus-box-shadow: 0 0 0.1rem 0.1rem rgba(173, 181, 189, 0.5);
