h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  color: var(--color-body);
  &[contenteditable='true'] {
    color: var(--color-body);
  }
}

p,
ul:not(.nav),
ol {
  line-height: var(--line-height-lg);
}

input:placeholder-shown {
  text-overflow: ellipsis;
}

input[type='checkbox'] {
  accent-color: var(--color-primary);
}

a:hover,
a:focus {
  text-decoration: none;
}

main {
  a {
    text-decoration: underline;
  }
}

header {
  color: var(--color-body);
}

footer {
  a {
    color: var(--color-body);
    @include media-breakpoint-up(lg) {
      &:hover {
        color: inherit;
        outline: 1px solid var(--color-border);
        outline-offset: 2px;
        border-radius: var(--radius);
      }
    }
  }
}

.header-heading-wrapper {
  font-family: 'Bad Script';
  font-weight: 400 !important;
}

#root {
  height: 100%;
  min-height: 100vh;
}

.app {
  position: relative;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

.loader-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tutorial-img {
  border: 1px solid var(--gray);
}

.container-main-inner {
  margin: 0 auto 80px;
  width: 100%;
  @include media-breakpoint-up(lg) {
    width: 80%;
  }
}

.alert {
  margin-top: 1rem;
}

.header-heading,
h2 {
  margin-bottom: 1rem;
}

header {
  .header-heading-wrapper {
    display: flex;
    margin-bottom: 10px;
    padding-top: 1rem;
    small {
      color: var(--color-body);
      display: none;
      font-size: 18px;
      align-self: flex-start;
      margin-left: 20px;
      word-spacing: -1px;
      @media (min-width: 768px) {
        display: block;
      }
    }
  }
  &.jumbotron {
    padding: 0;
    margin-bottom: 40px;
    background-color: var(--color-background-secondary);
    border-bottom: var(--border);
    @include media-breakpoint-up(md) {
      margin-bottom: 60px;
    }
  }
  & .container {
    position: relative;
  }
  .header-heading {
    font-size: 34px;
    line-height: 1.2;
  }
  .navbar {
    position: static;
    padding: 0;
  }
  .nav-link {
    padding: 0.5rem 1.5rem;
  }
  .nav {
    align-items: center;
    .nav-link,
    button {
      position: relative;
      color: var(--color-body);
      &::before {
        display: none;
        content: '';
        position: absolute;
        bottom: -1px;
        right: 1.5rem;
        left: 1.5rem;
        border-top: 3px solid var(--color-secondary);
      }
      &.active::before {
        display: block;
      }
      @include media-breakpoint-up(lg) {
        &:not(.active):hover::before {
          display: block;
          border-color: var(--color-secondary-lighter);
        }
      }
    }
  }
  .nav:first-child {
    li:first-of-type .nav-link,
    button {
      padding-left: 0;
      &::before {
        left: 0;
      }
    }
  }
  .nav:last-child {
    li:last-child .nav-link,
    button {
      padding-right: 0;
      &::before {
        right: 0;
      }
    }
  }
  .nav-item button {
    background: transparent;
    padding: 0.5rem 1rem;
    border: none;
    display: block;
    &:hover {
      color: $link-hover-color;
    }
  }
  @media (max-width: 767.98px) {
    .nav-secondary {
      position: absolute;
      top: 0;
      right: 15px;
      width: 45%;
      flex-wrap: nowrap;
      justify-content: flex-end;
      font-size: var(--font-size-sm);
    }
    .nav-secondary-loggedIn {
      display: none;
    }
  }
}

.dropdown-toggle::after {
  color: var(--dark);
}

.recipe-search-form {
  margin-bottom: 40px;
  .btn.recipe-search-form-button {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @include media-breakpoint-down(xs) {
    .input-group {
      display: block;
      button,
      input {
        width: 100%;
        border-radius: var(--radius) !important;
      }
      input {
        margin-bottom: $spacer;
      }
    }
  }
}

.recipe-search-form-alternative {
  .icon-pen {
    color: var(--color-dark-lighter);
    margin-left: 0.5em;
  }
}

.recipe-heading {
  margin-bottom: 0.5rem;
}

.recipe-heading {
  min-height: 40px;
  h2 {
    display: inline-block;
    margin: 0;
  }
  h3,
  h4 {
    display: inline;
    margin: 0 20px 0 0;
  }
}

.finder-button-save {
  display: inline;
  vertical-align: bottom;
  white-space: nowrap;
}

.nometadata-form {
  display: flex;
  align-items: center;
  input {
    min-width: 300px;
    max-width: 350px;
  }
  button {
    font-weight: 400;
    margin-left: 20px;
  }
}

.font-larger {
  font-size: 1.25rem;
}

.recipe-instructions {
  list-style: none;
  counter-reset: custom-counter;
  padding-left: 0;
  line-height: var(--line-height-lg);
  > li {
    display: flex;
    counter-increment: custom-counter;
    margin-bottom: 1rem;
    &::before {
      content: counter(custom-counter) '. ';
      color: var(--color-body);
      line-height: calc(1rem * var(--line-height-lg));
      font-weight: bold;
      font-size: 1.1em;
      margin-top: -0.05rem;
      margin-right: 0.5rem;
    }
  }
}

.text-muted ::before {
  color: var(--color-muted) !important;
}

.recipe-ingredients,
.recipe-instructions {
  margin-top: 30px;
  margin-bottom: 30px;
}

.recipe-servings {
  margin-top: -15px;
  font-style: italic;
}

.recipe-servings-content {
  display: inline-block;
}

.recipe-ingredients {
  padding-left: 40px;
}

.recipe-ingredients-columns {
  column-count: 2;
  column-width: 300px;
  li {
    margin-right: 20px;
  }
}

.auth-form-wrapper {
  max-width: 350px;
  h2 {
    margin-bottom: 1rem;
  }
  form {
    margin-bottom: 40px;
  }
}

.list-group {
  a {
    text-decoration: none;
  }
  .icon-delete {
    font-size: var(--font-size-sm);
    float: right;
    width: 20px;
    text-align: center;
    color: var(--gray);
    display: none;
    &:hover {
      color: var(--red);
    }
  }
  .list-group-item:hover {
    .icon-delete {
      @media (min-width: 992px) {
        display: inline-block;
      }
    }
  }
}

.list-group-item {
  border-bottom-color: var(--color-border);
  &:last-child {
    border-bottom: var(--border);
  }
}

.list-group-item-action {
  &,
  &:hover,
  &:focus {
    color: var(--color-body);
  }
}

.form-control {
  &,
  &:focus {
    color: var(--color-body);
  }
}

.form-group {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.action-bar {
  display: block;
  position: static;
  top: auto;
  width: var(--custom-width-1);
  bottom: -5rem;
  padding: 5px;
  margin-top: 2rem;
  background-color: var(--color-background-secondary);
  border: var(--border);
  border-radius: var(--radius);
  > button {
    display: flex;
    align-items: center;
    margin-left: 4px;
    margin-bottom: 10px;
    svg {
      margin-right: 0.5em;
    }
    &.btn-primary {
      margin-left: 16px;
    }
    &:first-child {
      margin-top: 10px;
    }
  }
  @media (min-width: 1100px) {
    // var(--custom-width-2)
    position: absolute;
    top: 0;
    bottom: auto;
    right: auto;
    left: calc(100% + 20px);
    width: 140px;
    margin: 0;
  }
}

.button-icon-save {
  svg {
    font-weight: normal;
  }
}

.btn.button-outline-gray {
  color: var(--color-body);
  border-color: transparent;
  transition: none;
  svg {
    color: var(--color-dark-lighter);
  }
  .js-focus-visible &:focus:not(.focus-visible) {
    box-shadow: none;
  }
  &:focus {
    box-shadow: var(--form-control-box-shadow-solid);
  }
  @include media-breakpoint-up(lg) {
    &:hover {
      border-color: var(--color-border);
    }
  }
}

.recipe {
  position: relative;
}

.infobox {
  display: inline-block;
  padding: 20px;
  border: 1px solid var(--gray);
  border-radius: var(--radius);
  button {
    white-space: nowrap;
  }
}

a.header-heading-anchor {
  color: var(--color-body);
  text-decoration: none;
}

/* Footer */
.footer {
  color: var(--color-body);
  text-align: right;
  margin-top: auto;
  font-size: 0.8rem;
  padding: 0.5em 0;
  @include media-breakpoint-up(lg) {
    font-size: var(--font-size-sm);
    background-color: var(--color-background-secondary);
    border-top: var(--border);
  }
}

.footer-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.footer-link {
  display: none;
  @include media-breakpoint-up(lg) {
    display: block;
  }
}

.footer-link,
.footer-copyright {
  @include media-breakpoint-up(lg) {
    width: auto;
    padding: 0;
    margin-left: 20px;
  }
}

.recipe-listing-search {
  width: var(--custom-width-1);
  margin-bottom: 40px;
  align-items: center;
  input {
    padding-right: 30px;
    border-radius: 0.25rem !important;
  }
  .icon-search {
    position: absolute;
    right: 10px;
    z-index: 10;
    color: var(--gray);
  }
}

.recipeUrl {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 15px;
  &.recipeUrl-editable {
    white-space: normal;
    word-break: break-all;
    overflow: visible;
    text-overflow: initial;
  }
  a {
    display: inline;
  }
  a[contenteditable='true'] {
    text-decoration: none;
    display: inline-block;
    &,
    &:hover {
      color: var(--color-body);
    }
  }
}

.page-legal {
  h4 {
    margin-top: 2rem;
  }
}

.button-fullWidth {
  width: 100%;
}
