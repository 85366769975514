html {
  min-height: 100vh;
  font-size: 112.5%;
}

body {
  min-height: 100vh;
  color: var(--color-body);
  font-family: 'Noto Sans', sans-serif;
  background: var(--color-background-primary);
  background-image: url('../img/groovepaper.png');
}

// Hide x button from search input in Chrome
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
