@media print {
  .action-bar,
  .footer,
  header {
    display: none;
  }
  .container-main {
    margin-top: 40px;
  }
}
