:root {
  // main colors
  --color-primary: #{$primary};
  --color-secondary: #{$secondary};
  --color-secondary-lighter: #e1aa9d;
  --color-dark: #{$dark};
  --color-dark-lighter: #616161;

  // font colors
  --color-body: #{$body-color};
  --color-muted: #{$text-muted};

  // background colors
  --color-background-primary: rgba(255, 223, 181, 0.2);
  --color-background-secondary: rgba(0, 0, 0, 0.03);

  // borders
  --color-border: #{$border-color};
  --color-border-lighter: #{$border-color-lighter};
  --border: 1px solid #{$border-color};

  // sizing
  --input-padding: #{$input-padding-y} #{$input-padding-x};

  --radius: #{$border-radius};

  --font-size-sm: 0.875rem;

  --line-height: 1.5;
  --line-height-lg: 1.65;
  @include media-breakpoint-up(md) {
    --line-height-lg: 1.75;
  }

  --form-control-box-shadow: #{$input-focus-box-shadow};
  --form-control-box-shadow-solid: 0 0 0 0.1rem var(--color-dark);

  --custom-width-1: 230px;
  --custom-width-2: 1100px;
}
