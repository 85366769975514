[contenteditable='true'] {
  padding: var(--input-padding);
  background-color: #fff;
  min-width: 250px;
  max-width: 100%;
  border: var(--border);
  outline: 0;
  border-radius: var(--radius);
  cursor: text;

  &:focus {
    box-shadow: var(--form-control-box-shadow);
  }

  &[data-placeholder]:empty::before {
    content: attr(data-placeholder);
    color: var(--color-muted);
  }

  @include transition($input-transition);

  &.recipe-ingredients {
    padding-left: 40px;
  }

  &.recipe-instructions {
    padding-top: 1rem;
  }
}
